import * as React from "react";

// Components
import Layout from "../components/Layouts/Layouts";
import Seo from "../components/Layouts/Seo";
import ItemsJS from "../components/Content/Product/Search";
import BannerBasic from "../components/Content/Banner/Basic";

// markup
const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Seo
        title="Products"
        description="Conex Bänninger products are available through Reece, Actrol, and Metalflex stores. With over 600+ locations Australia-wide, they’re available near you."
      />
      <BannerBasic title="Products" />
      <ItemsJS />
    </Layout>
  );
};

export default IndexPage;
